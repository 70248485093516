<template>
  <p v-if="blok.facility_title || blok.commercialName" class="size-xs">{{ blok.facility_title || blok.commercialName }}</p>
  <h2 v-if="blok.map_title_popin" class="map-modal-title size-l">{{ blok.map_title_popin }}</h2>
  <div class="wrapper">
    <GlobalMap v-if="blok?.latitude && blok?.longitude && polygons?.length" :blok="blok" :polygons="true" :polygons-data="polygons" />
    <div v-if="mapDescription" class="map-modal-description size-xs" v-html="mapDescription"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const mapDescription = computed(() => {
  return typeof props.blok.map_description_popin === "object"
    ? useRenderRichText(props.blok.map_description_popin)
    : props.blok.map_description_popin;
});

const type1PolygonColor = useCssVar(ref('--polygon-1'), ref(null)).value;

let polygons = [];
const defaultPolygonArray = props.blok.polygons ? JSON.parse(props.blok.polygons) : null;

defaultPolygonArray?.forEach((item) => {
  item.polygon?.forEach((element) => {
    for (let [key, value] of Object.entries(element)) {
      element[key] = parseFloat(value);
    }
  });
});

polygons = defaultPolygonArray?.filter(item => item.type === "MAD").map((item) => {
  item.paths = [...item.polygon];
  item.strokeColor = type1PolygonColor;
  item.fillColor = type1PolygonColor;
  item.strokeOpacity = 1;
  item.strokeWeight = 2;
  item.fillOpacity = 0.4;
  return item;
});
</script>
<style lang="scss" scoped>
.map-modal-title {
  margin-top: 0.5rem;
  margin-bottom: 2rem;

  @include for-tablet-landscape-up {
    margin-bottom: 4rem;
  }
}
.wrapper {
  width: 100%;
  
  @include for-tablet-landscape-up {
    display: flex;
    width: auto;
    margin: 0 auto;
  }
}
.map-modal-description {
  @include for-tablet-landscape-up {
    width: col-span(8);
  }
}
</style>
<style lang="scss">
.map-modal {
  .map {
    overflow: hidden;
    border-radius: $radius-l;
    margin-bottom: 2rem;
    height: auto;
    aspect-ratio: 1 / 1;

    @include for-tablet-landscape-up {
      width: col-span(5);
      margin-right: col-span(1);
      margin-bottom: 0;
    }
  }
} 
</style>
